export const Status = {
  DEFAULT: 'DEFAULT',
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE'
};

export const PublicKeySharingMethod = {
  UNKNOWN: 'UNKNOWN',
  STORED_SECRET: 'STORED_SECRET',
  JWKS_URL: 'JWKS_URL',
  OKTA: 'OKTA',
  JWTASSERTION: 'JWTASSERTION'
};

export const WebhookAuthenticationType = {
  NONE: 'NONE',
  BASIC: 'BASIC',
  BEARER: 'BEARER',
  DIGEST: 'DIGEST',
  XAPIKEY: 'XAPIKEY',
  CUSTOM: 'CUSTOM'
};

export const PartnerType = {
  UNKNOWN: 'PartnerType_UNKNOWN',
  IOS_CLIENT_SDK: 'PartnerType_IOS_CLIENT_SDK',
  ANDROID_CLIENT_SDK: 'PartnerType_ANDROID_CLIENT_SDK',
  WEB_CLIENT_SDK: 'PartnerType_WEB_CLIENT_SDK',
  API: 'PartnerType_API'
};

export const PartnerAccessLevel = {
  UNKNOWN: 'PartnerAccessLevel_UNKNOWN',
  READWRITE: 'PartnerAccessLevel_READWRITE',
  READONLY: 'PartnerAccessLevel_READONLY'
};

export const WebhookFormat = {
  UNKNOWN: 'WebhookFormat_UNKNOWN',
  CANONICALV1: 'WebhookFormat_CanonicalV1',
  NOTIFICATION: 'WebhookFormat_Notification',
  FHIR: 'WebhookFormat_FHIR'
};

export const PageRequestType = {
  DEFAULT: 'DEFAULT',
  TIME: 'TIME',
  CURSOR: 'CURSOR'
};

// Local storage keys
export const LOCAL_STORE = {
  ID_TOKEN: 'ID_TOKEN',
  USER_ID: 'USER_ID',
  OKTA_TOKEN_STORAGE: 'okta-token-storage',
  ORGANIZATION_ID: 'ORGANIZATION_ID'
};

// Request headers
export const RequestHeaders = {
  AUTHORIZATION: 'Authorization',
  ORGANIZATION_ID: 'X-Suki-Organization-Id',
  USER_ID: 'X-Suki-User-Id'
};

// Form validation rules
export const ValidationRules = {
  REQUIRED: { required: true, message: 'This field is required' },
  URL: { type: 'url', message: 'Please enter a valid URL' },
  EMAIL: { type: 'email', message: 'Please enter a valid email' },
  NUMBER: { type: 'number', message: 'Please enter a valid number' }
};

// Non-updatable fields as per protobuf
export const NON_UPDATABLE_FIELDS = ['name', 'status', 'partnerId'];

// Fields not allowed to update if status is UNKNOWN or DEFAULT
export const RESTRICTED_UPDATE_FIELDS = [
  'publicKeySharingMethod',
  'partnerType',
  'accessLevel'
];
