import axios from 'axios';
import store from 'store2';

import { LOCAL_STORE, RequestHeaders } from './constants';

const BASE_URL = window.location.origin.includes('manage') ? window.location.origin.replace('manage', 'gateway') : window.location.origin

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
    'Access-Control-Allow-Origin': '*'
  }
});

instance.interceptors.request.use((config) => {
    const organizationId = store.get(LOCAL_STORE.ORGANIZATION_ID)
    const userId = store.get(LOCAL_STORE.USER_ID)
    const authorization = store.get(LOCAL_STORE.OKTA_TOKEN_STORAGE)?.accessToken?.value
    const headers = config?.headers || {}
  
    if (organizationId) {
      headers[RequestHeaders.ORGANIZATION_ID] = organizationId
    }
    if (userId) {
      headers[RequestHeaders.USER_ID] = userId
    }
    if (authorization) {
      headers[RequestHeaders.AUTHORIZATION] = `Bearer ${authorization}`
    }
  
    return config
});

export default instance;