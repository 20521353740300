import styled from 'styled-components';
import { Layout, Menu, Button, Typography } from 'antd';

const { Sider } = Layout;
const { Text } = Typography;


export const StyledSider = styled(Sider)`
  background: #fff !important;
  border-right: 1px solid #edf2f7;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const PartnerName = styled(Text)`
  color: ${props => props.inactive ? '#94a3b8' : '#1e293b'};
`;

export const PartnerDomain = styled(Text)`
  font-size: 12px;
  color: #64748b;
`; 

export const StyledMenu = styled(Menu)`
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: none;
  padding: 8px;
  height: calc(100% - 120px);

  .ant-menu-item {
    height: auto;
    padding: 12px 16px;
    margin: 4px 0;
    
    &.ant-menu-item-selected {
      &::after {
        display: none;
      }
      
      ${PartnerName} {
        color: #1890ff !important;
        font-weight: 500;
      }
      
      ${PartnerDomain} {
        color: #1890ff;
        opacity: 0.8;
      }
    }
  }

  // Override Ant Design's default focus styles
  .ant-menu-item:active,
  .ant-menu-item-selected {
    background-color: #e6f7ff !important;
  }

  // Improve inactive partner styling
  ${PartnerName}[inactive="true"] {
    color: #94a3b8;
  }
`;

export const HeaderContainer = styled.div`
  padding: 16px;
  border-bottom: 1px solid #edf2f7;
  background: #fff;
`;

export const PaginationContainer = styled.div`
  padding: 16px;
  border-top: 1px solid #edf2f7;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TotalCount = styled.span`
  color: #64748b;
  font-size: 14px;
`;

export const PartnerInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const NewPartnerButton = styled(Button)`
  height: 40px;
  border-radius: 6px;
  font-weight: 500;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.05);
  width: 100%;
`;

export const ErrorContainer = styled.div`
  text-align: center;
  padding: 24px;
`;