// USER_PERSONA is decided based on pathname match
// TODO Consolidate with ROLES (below)
export const USER_PERSONA = {
  NO_ACCESS: 'NO_ACCESS',
  PHYSICIAN: 'PHYSICIAN',
  SCRIBE: 'SCRIBE',
  ADMIN: 'ADMIN'
}

// 60 minute timeout.
export const INACTIVITY_TIMEOUT = 1000 * 60 * 59.5

export const LO_STO = {
  CURRENT_USER_ID: 'CURRENT_USER_ID',
  CURRENT_ORG_ID: 'CURRENT_ORG_ID',
  CURRENT_ORG: 'CURRENT_ORG',
  CURRENT_USER_EMAIL: 'CURRENT_USER_EMAIL',
  CURRENT_USER_ROLES: 'CURRENT_USER_ROLES',
  SESSION_ID: 'SESSION_ID',
  USER_ID: 'USER_ID',
  USER_PERSONA: 'USER_PERSONA',
  USER_ROLES: 'USER_ROLES',
  VERSION: 'VERSION',
  ORGANIZATION_ID: 'ORGANIZATION_ID',
  SESSION_TOKEN: 'SESSION_TOKEN',
  ID_TOKEN: 'ID_TOKEN',
  USER_NOTES_PANEL_FILTER: 'USER_NOTES_PANEL_FILTER',
  ALTAIS_ERROR: 'ALTAIS_ERROR'
}

export const ROUTES = {
  ROOT: '/',
  LOGOUT: '/logout',
  ADMIN_ROOT: '/',
  ADMIN_VIEW: '/:view',
  ORGS: '/orgs',
  USERS: 'users',
  APPTS: 'appointments',
  COMMAND_CENTRAL: 'commands',
  EDITOR_SANDBOX: '/editorsandbox',
  NOTE_TYPES: 'notetypes',
  SPECIALITIES: '/specialities',
  PREFERENCES: '/preferences',
  SCRIPTS: 'scripts',
  NOTE: 'note',
  PATIENT_NOTE: 'patientnote',
  IMPLICIT_CALLBACK: '/implicit/callback',
  EMR: 'emr',
  SECTIONS: 'sections',
  NVOQ: 'nVoq',
  PENDING_USERS: 'pendingusers',
  FEATURE_FLAGS: '/feature-flags',
  PARTNERS: '/partners'
}

export const RTE = {
  OPTIONS: { defaultBlockTag: 'span' }
}

export const ENVIRONMENTS = {
  LOCAL: 'local',
  DEV: 'dev',
  TEST: 'test',
  STAGE: 'stage',
  PROD: 'prod'
}

export const EDITOR_TYPE = {
  SCRIPT: 'SCRIPT',
  NOTETYPE: 'NOTETYPE',
  NOTE: 'NOTE',
  DETAILED: 'DETAILED'
}

// Create User
export const ROLES = {
  USER: 'USER',
  SCRIBE: 'SCRIBE',
  ADMIN: 'ADMIN'
}

export const GENDERS = {
  FEMALE: 'FEMALE',
  MALE: 'MALE',
  UNKNOWN: 'UNKNOWN'
}

export const NOTE_STATUS = {
  INCOMPLETE: 'INCOMPLETE',
  USER_IS_DONE: 'USER_IS_DONE',
  SUBMITTED_TO_EMR: 'SUBMITTED_TO_EMR',
  IMPORTED_FROM_EMR: 'IMPORTED_FROM_EMR',
  NEED_DOCTOR_SIGN_OFF: 'NEED_DOCTOR_SIGN_OFF'
}

export const SUBMISSION_WARNING = {
  UNKNOWN: 'UNKNOWN',
  NO_ENCOUNTER: 'NO_ENCOUNTER',
  ENCOUNTER_NOT_OPEN: 'ENCOUNTER_NOT_OPEN',
  UNKNOWN_PATIENT: 'UNKNOWN_PATIENT'
}

export const INTENTS = [
  'AGENT_WAKEUP',
  'AGENT_PROCESSING',
  'AGENT_CANCELLED',
  'RESTART_STREAM',
  'SECTION',
  'STOP_DICTATION',
  'TRANSCRIPT',
  'SCHEDULE',
  'SUBMIT_NOTE',
  'DELETE_NOTE',
  'DISMISS',
  'PROFILE',
  'PRINT_NOTE',
  'CREATE_NOTE',
  'MACRO',
  'UNFINISHED_NOTES',
  'GO_BACK',
  'CREATE_NOTE_FROM_NOTETYPE_FOR_PATIENT',
  'COPY_FORWARD',
  'SCRIPT_CHANGE',
  'NEW_LINE',
  'XRAY',
  'VEIN_MAPPING',
  'URINE_CULTURE'
]

export const SLOT_TYPE = {
  SECTION: 'SECTION',
  PATIENT: 'PATIENT',
  NOTETYPE: 'NOTETYPE',
  MACRO: 'MACRO',
  TRANSCRIPT: 'TRANSCRIPT'
}

export const UI_VIEW = {
  NOTE: 'NOTE',
  WELCOME: 'WELCOME',
  SCHEDULE: 'SCHEDULE',
  PROFILE: 'PROFILE',
  SECTION_TRANSCRIPT: 'SECTION_TRANSCRIPT',
  UNFINISHED_NOTES: 'UNFINISHED_NOTES'
}

export const NOTE_MODE = {
  QA: 'qa',
  DEBUG: 'debug',
  PLAIN: 'plain',
  MECHANIC: 'mechanic',
  DEFAULT: 'qa'
}

export const NOTE_MODE_STRING = {
  QA: 'QA',
  DEBUG: 'Debug',
  PLAIN: 'Plain',
  MECHANIC: 'Mechanic',
  DEFAULT: 'QA'
}

export const GRAPHQL_TYPE = {
  COMPOSITION: 'Composition',
  PATIENT_NOTE: 'PatientNote'
}

export const TIMELINE_MODE = {
  DIF: 'dif',
  DEFAULT: 'default',
  FILTERED: 'filtered',
  UNFILTERED: 'unfiltered',
  S2: 's2'
}

// Minimum length of audio file that will be displayed in admin note, any less
// can still be seen in lighthouse
export const MIN_AUDIO_DURATION = 500

export const SCRIPT_TAG = {
  NAME: 'MACRO_NAME',
  TYPE: 'MACRO_TYPE'
}

// More information on these fetch policies can be found at:
// https://www.apollographql.com/docs/react/api/react-apollo.html#graphql-config-options-fetchPolicy
export const FETCH_POLICY = {
  CACHE_FIRST: 'cache-first',
  CACHE_AND_NETWORK: 'cache-and-network',
  NETWORK_ONLY: 'network-only',
  CACHE_ONLY: 'cache-only',
  NO_CACHE: 'no-cache'
}

export const EMR_DESTINATION = {
  SUKI_ONLY: 'SUKI_ONLY',
  ENCOUNTER: 'ENCOUNTER',
  DOCUMENT: 'DOCUMENT'
}

export const DEVICE_NAMES = {
  IOS_MOBILE: 'iOS Mobile',
  IOS_TABLET: 'iOS Tablet',
  CHROME: 'Chrome',
  ANDROID_MOBILE: 'Android Mobile',
  ANDROID_TABLET: 'Android Tablet',
  UNKNOWN: 'Unknown Device'
}

export const PAGE_LIMIT = {
  ALL_NOTES: 20
}

export const QUERY_PARAMS = {
  ORG_ID: 'orgId',
  USER_ID: 'userId',
  NOTETYPE_ID: 'noteTypeId',
  SCRIPT_ID: 'scriptId',
  SPECIALITY: 'speciality'
}

export const SPECIALTY_TYPE = {
  NA: 'NA',
  ACCIDENT_AND_INJURY: 'ACCIDENT_AND_INJURY',
  ADDICTION_MEDICINE: 'ADDICTION_MEDICINE',
  ADULT_CONGENITAL_HEART_DISEASE: 'ADULT_CONGENITAL_HEART_DISEASE',
  ANESTHESIOLOGY: 'ANESTHESIOLOGY',
  ALLERGY_AND_IMMUNOLOGY: 'ALLERGY_AND_IMMUNOLOGY',
  BEHAVIORAL_HEALTH: 'BEHAVIORAL_HEALTH',
  BONE_MARROW_TRANSPLANT: 'BONE_MARROW_TRANSPLANT',
  CANCER_GENETICS: 'CANCER_GENETICS',
  CARDIAC_IMAGING: 'CARDIAC_IMAGING',
  CARDIOLOGY_AND_ELECTROPHYSIOLOGY: 'CARDIOLOGY_AND_ELECTROPHYSIOLOGY',
  CARDIOLOGY: 'CARDIOLOGY',
  COMPREHENSIVE_CARE: 'COMPREHENSIVE_CARE',
  CONCIERGE_MEDICINE: 'CONCIERGE_MEDICINE',
  CRITICAL_CARE: 'CRITICAL_CARE',
  DERMATOLOGY: 'DERMATOLOGY',
  DEVELOPMENTAL_AND_BEHAVIORAL_PEDIATRICS: 'DEVELOPMENTAL_AND_BEHAVIORAL_PEDIATRICS',
  DRUG_AND_ALCOHOL_REHAB: 'DRUG_AND_ALCOHOL_REHAB',
  EMERGENCY_MEDICINE: 'EMERGENCY_MEDICINE',
  ENDOCRINOLOGY: 'ENDOCRINOLOGY',
  EPILEPSY: 'EPILEPSY',
  FAMILY_MEDICINE: 'FAMILY_MEDICINE',
  FUNCTIONAL_MEDICINE: 'FUNCTIONAL_MEDICINE',
  GASTROENTEROLOGY: 'GASTROENTEROLOGY',
  GASTROENTEROLOGY_ONCOLOGY: 'GASTROENTEROLOGY_ONCOLOGY',
  GENETICS: 'GENETICS',
  GERIATRICS: 'GERIATRICS',
  GYN_ONCOLOGY: 'GYN_ONCOLOGY',
  HEADACHE: 'HEADACHE',
  HEART_FAILURE_AND_TRANSPLANT_CARDIOLOGY: 'HEART_FAILURE_AND_TRANSPLANT_CARDIOLOGY',
  HEMATOLOGY: 'HEMATOLOGY',
  HEMATOLOGY_AND_ONCOLOGY: 'HEMATOLOGY_AND_ONCOLOGY',
  HEPATOLOGY: 'HEPATOLOGY',
  HOSPITAL_MEDICINE: 'HOSPITAL_MEDICINE',
  INFECTIOUS_DISEASE: 'INFECTIOUS_DISEASE',
  INPATIENT_PSYCHIATRY: 'INPATIENT_PSYCHIATRY',
  INTEGRATIVE_MEDICINE: 'INTEGRATIVE_MEDICINE',
  INTERNAL_MEDICINE: 'INTERNAL_MEDICINE',
  INTERVENTIONAL_CARDIOLOGY: 'INTERVENTIONAL_CARDIOLOGY',
  INTERVENTIONAL_PAIN: 'INTERVENTIONAL_PAIN',
  INVASIVE_CARDIOLOGY: 'INVASIVE_CARDIOLOGY',
  LACTATION: 'LACTATION',
  MEDICAL_ONCOLOGY: 'MEDICAL_ONCOLOGY',
  MEDICINE_BARIATRIC: 'MEDICINE_BARIATRIC',
  NEPHROLOGY: 'NEPHROLOGY',
  NEUROIMMUNOLOGY: 'NEUROIMMUNOLOGY',
  NEUROLOGY: 'NEUROLOGY',
  NEUROMUSCULOSKELETAL_MANIPULATIVE_MEDICINE: 'NEUROMUSCULOSKELETAL_MANIPULATIVE_MEDICINE',
  NEUROSURGERY: 'NEUROSURGERY',
  NUCLEAR_MEDICINE: 'NUCLEAR_MEDICINE',
  NUTRITION: 'NUTRITION',
  OBSTETRICS_GYNECOLOGY: 'OBSTETRICS_GYNECOLOGY',
  OCCUPATIONAL_MEDICINE: 'OCCUPATIONAL_MEDICINE',
  ONCOLOGY: 'ONCOLOGY',
  ONCOLOGY_BREAST: 'ONCOLOGY_BREAST',
  ONCOLOGY_CUTANEOUS: 'ONCOLOGY_CUTANEOUS',
  ONCOLOGY_GENITOURINARY: 'ONCOLOGY_GENITOURINARY',
  ONCOLOGY_HEAD_AND_NECK: 'ONCOLOGY_HEAD_AND_NECK',
  ONCOLOGY_ORTHOPEDIC: 'ONCOLOGY_ORTHOPEDIC',
  ONCOLOGY_THORACIC: 'ONCOLOGY_THORACIC',
  ONCOLOGY_WOMENS: 'ONCOLOGY_WOMENS',
  OPHTHALMOLOGY: 'OPHTHALMOLOGY',
  ORTHOPEDICS_HAND: 'ORTHOPEDICS_HAND',
  ORTHOPEDIC_SURGERY: 'ORTHOPEDIC_SURGERY',
  OTOLARYNGOLOGY_HEAD_AND_NECK: 'OTOLARYNGOLOGY_HEAD_AND_NECK',
  PAIN_MANAGEMENT: 'PAIN_MANAGEMENT',
  PALLIATIVE_MEDICINE: 'PALLIATIVE_MEDICINE',
  PATHOLOGY: 'PATHOLOGY',
  PEDIATRIC_ADOLESCENT: 'PEDIATRIC_ADOLESCENT',
  PEDIATRIC_ALLERGY_AND_IMMUNOLOGY: 'PEDIATRIC_ALLERGY_AND_IMMUNOLOGY',
  PEDIATRIC_CARDIOLOGY: 'PEDIATRIC_CARDIOLOGY',
  PEDIATRIC_CRITICAL_CARE: 'PEDIATRIC_CRITICAL_CARE',
  PEDIATRIC_ENDOCRINOLOGY: 'PEDIATRIC_ENDOCRINOLOGY',
  PEDIATRIC_HOSPITAL_MEDICINE: 'PEDIATRIC_HOSPITAL_MEDICINE',
  PEDIATRIC_GASTROENTEROLOGY: 'PEDIATRIC_GASTROENTEROLOGY',
  PEDIATRIC_NEPHROLOGY: 'PEDIATRIC_NEPHROLOGY',
  PEDIATRIC_PSYCHIATRY: 'PEDIATRIC_PSYCHIATRY',
  PEDIATRIC_PULMONOLOGY: 'PEDIATRIC_PULMONOLOGY',
  PEDIATRIC_RHEUMATOLOGY: 'PEDIATRIC_RHEUMATOLOGY',
  PEDIATRICS: 'PEDIATRICS',
  PHYSICAL_MEDICINE_AND_REHABILITATION: 'PHYSICAL_MEDICINE_AND_REHABILITATION',
  PLASTIC_SURGERY: 'PLASTIC_SURGERY',
  PODIATRY: 'PODIATRY',
  PREVENTATIVE_MEDICINE: 'PREVENTATIVE_MEDICINE',
  PSYCHIATRY: 'PSYCHIATRY',
  PULMONOLOGY: 'PULMONOLOGY',
  RADIATION_ONCOLOGY: 'RADIATION_ONCOLOGY',
  RADIOLOGY_DIAGNOSTIC: 'RADIOLOGY_DIAGNOSTIC',
  RADIOLOGY_INTERVENTIONAL: 'RADIOLOGY_INTERVENTIONAL',
  REPRODUCTIVE_ENDOCRINOLOGY_AND_INFERTILITY: 'REPRODUCTIVE_ENDOCRINOLOGY_AND_INFERTILITY',
  RHEUMATOLOGY: 'RHEUMATOLOGY',
  SLEEP_MEDICINE: 'SLEEP_MEDICINE',
  SPINAL_ONCOLOGY_AND_SPINE_TUMOR_SURGERY: 'SPINAL_ONCOLOGY_AND_SPINE_TUMOR_SURGERY',
  SPINE_SURGERY: 'SPINE_SURGERY',
  SPORTS_MEDICINE: 'SPORTS_MEDICINE',
  SURGERY_BARIATRICS: 'SURGERY_BARIATRICS',
  SURGERY_BREAST: 'SURGERY_BREAST',
  SURGERY_CARDIAC_AND_THORACIC: 'SURGERY_CARDIAC_AND_THORACIC',
  SURGERY_CARDIOVASCULAR: 'SURGERY_CARDIOVASCULAR',
  SURGERY_COLON_AND_RECTAL: 'SURGERY_COLON_AND_RECTAL',
  SURGERY_GENERAL: 'SURGERY_GENERAL',
  SURGERY_ONCOLOGY: 'SURGERY_ONCOLOGY',
  SURGERY_PEDIATRIC: 'SURGERY_PEDIATRIC',
  SURGERY_THORACIC: 'SURGERY_THORACIC',
  SURGERY_TRAUMA: 'SURGERY_TRAUMA',
  SURGERY_VASCULAR: 'SURGERY_VASCULAR',
  TRANSPLANT_HEPATOLOGY: 'TRANSPLANT_HEPATOLOGY',
  TRANSPLANT_INTESTINE: 'TRANSPLANT_INTESTINE',
  TRANSPLANT_NEPHROLOGY: 'TRANSPLANT_NEPHROLOGY',
  TRANSPLANT_PANCREAS: 'TRANSPLANT_PANCREAS',
  UROLOGY: 'UROLOGY',
  UNLISTED_MEDICAL: 'UNLISTED_MEDICAL',
  UNLISTED_SURGICAL: 'UNLISTED_SURGICAL',
  URGENT_CARE: 'URGENT_CARE',
  UROGYNECOLOGY: 'UROGYNECOLOGY',
  VASCULAR_NEUROLOGY: 'VASCULAR_NEUROLOGY',
  VETERINARIAN: 'VETERINARIAN',
  VETERINARY_EMERGENCY_AND_CRITICAL_CARE: 'VETERINARY_EMERGENCY_AND_CRITICAL_CARE',
  VETERINARY_URGENT_CARE: 'VETERINARY_URGENT_CARE',
  WOUND_CARE: 'WOUND_CARE'
}

export const SPECIALTIES_LIST = Object.keys(SPECIALTY_TYPE)

export const ORG_IDS = {
  SUKI: '11111111-1111-1111-1111-111111111111',
  SUKI_ONBOARDING_DEFAULTS: '55555555-5555-5555-5555-555555555555'
}

export const BACKDOOR_WHITE_LIST = [
  // for backdoor release all notes

  // engineering team
  'ganesh@suki.ai',
  'maneesh@suki.ai',
  'jayant@suki.ai',
  'rcunha@suki.ai',
  'sramineni@suki.ai',
  'bburli@suki.ai',
  'uchoudhary@suki.ai',

  // CSM team
  'shatfield@suki.ai',
  'tracy@suki.ai',
  'meghan@suki.ai',
  'mvanwinkle@suki.ai',
  'sudarsan@suki.ai',
  'ddhananjay@suki.ai',
  'cmanamohan@suki.ai',
  'hmalleshaiah@suki.ai',

  // TS team
  'mpalanisamy@suki.ai',
  'ppadmanabhan@suki.ai',
  'sselvaraj@suki.ai',
  'balvarez@suki.ai',
  'seaswaramoorthy@suki.ai',
  'gcushenbery@suki.ai',
  'gbipin@suki.ai',
  'skumar@suki.ai',
  'vkumar@suki.ai'
]

export const NOTE_QUEUE_STATUS = {
  NOTE_RELEASED_SUCCESSFULLY: 'NOTE_RELEASED_SUCCESSFULLY',
  RELEASED_ALL_NOTES: 'RELEASED_ALL_NOTES',

  NOTE_IS_BEING_EDITED: 'NOTE_IS_BEING_EDITED',
  NOTE_IS_AVAILABLE: 'NOTE_IS_AVAILABLE',
  NOTE_DOESNT_EXIST: 'NOTE_DOESNT_EXIST',

  NOTE_REMOVED_SUCCESSFULLY: 'NOTE_REMOVED_SUCCESSFULLY',
  NOTE_REMOVED_SUCCESSFULLY_WHILE_BEING_PROCESSED: 'NOTE_REMOVED_SUCCESSFULLY_WHILE_BEING_PROCESSED',
  NOT_AUTHORIZED: 'NOT_AUTHORIZED'
}

export const Z_INDEX = {
  VEIL: 1000,
  ACTION_BAR: 5000,
  MODAL: 7000
}

export const GQL_STATUS = {
  FAILED_PRECONDITION: 'FAILED_PRECONDITION'
}

export const MACRO_SOURCE = {
  DEFAULT: 'DEFAULT',
  ADMIN: 'ADMIN',
  USER: 'USER'
}

export const EMR_TYPE = {
  ALLSCRIPTS: 'ALLSCRIPTS',
  ATHENA: 'ATHENA',
  CERNER: 'CERNER',
  ELATION: 'ELATION',
  EPIC: 'EPIC',
  GREENWAY: 'GREENWAY',
  NEXTGEN: 'NEXTGEN',
  MEDITECH: 'MEDITECH'
}

export const SECONDARY_EMR_TYPE = {
  ATHENA_EMR: 'ATHENA_EMR',
  ELATION_EMR: 'ELATION_EMR',
  CERNER_EMR: 'CERNER_EMR',
  EPIC_EMR: 'EPIC_EMR'
}
