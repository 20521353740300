import React, { useEffect, useState } from 'react';
import { 
  Form,
  Input,
  Select,
  Row,
  Col,
  Space,
  InputNumber,
  Tabs,
  Alert,
  Spin,
  message
} from 'antd';
import { 
  Status,
  PublicKeySharingMethod,
  WebhookAuthenticationType,
  PartnerType,
  PartnerAccessLevel,
  WebhookFormat,
  ValidationRules 
} from '../../constants';
import { 
  transformPartnerToForm, 
  transformFormToPartner, 
  getUpdatePaths,
  validatePartner 
} from '../../helpers';
import {
  createPartner,
  updatePartner,
  activatePartner,
  deactivatePartner
} from '../../api';
import {
  FormContainer,
  FormHeader,
  StyledTitle,
  ActionButton,
  StyledCard,
  StyledTabs,
  FormActions
} from './styled';
import PropTypes from 'prop-types';

const INITIAL_VALUES = {
  status: Status.DEFAULT,
  publicKeySharingMethod: PublicKeySharingMethod.UNKNOWN,
  webhookAuthType: WebhookAuthenticationType.NONE,
  partnerType: PartnerType.UNKNOWN,
  accessLevel: PartnerAccessLevel.UNKNOWN,
  webhookFormat: WebhookFormat.UNKNOWN,
  webhookSettings: {
    retryCount: 3,
    timeoutSeconds: 5
  },
  webhookAuthDetails: {},
  authenticationDetails: {}
}

const { Option } = Select;
const { TabPane } = Tabs;

const PartnerForm = ({ partner, onSubmit }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (partner) {
      form.setFieldsValue(transformPartnerToForm(partner));
    } else {
      // Reset all fields first
      form.resetFields();
      // Set default values for new partner
      form.setFieldsValue(INITIAL_VALUES);
    }
  }, [partner, form]);

  const handleFormSubmit = async (formData) => {
    try {
      // Validate form data
      const validationErrors = validatePartner(formData);
      if (validationErrors.length > 0) {
        setError(validationErrors.join(', '));
        return;
      }

      setLoading(true);
      setError(null);
      const partnerData = transformFormToPartner(formData);
      
      if (partner?.partnerId) {
        const updatePaths = getUpdatePaths(partner, partnerData);
        
        if (updatePaths.paths.length === 0) {
          message.info("No changes to update");
          return;
        }

        await updatePartner({...partnerData, partnerId: partner.partnerId}, updatePaths.paths?.join(","));
        message.success("Partner updated successfully");
        onSubmit({ ...partner, ...partnerData });
      } else {
        const response = await createPartner(partnerData);
        message.success("Partner created successfully");
        onSubmit(response.partner);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Failed to save partner";
      setError(errorMessage);
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleActivateDeactivate = async (action) => {
    if (!partner?.partnerId) return;

    try {
      setLoading(true);
      setError(null);

      if (action === 'activate') {
        await activatePartner(partner.partnerId);
        message.success("Partner activated successfully");
        onSubmit({ ...partner, status: Status.ACTIVE });
      } else {
        await deactivatePartner(partner.partnerId);
        message.success("Partner deactivated successfully");
        onSubmit({ ...partner, status: Status.INACTIVE });
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || `Failed to ${action} partner`;
      setError(errorMessage);
      message.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Spin spinning={loading}>
      <FormContainer>
        <Space direction="vertical" style={{ width: '100%' }} size={24}>
          <FormHeader>
            <StyledTitle level={3}>
              {partner ? `${partner.name}` : 'New Partner'}
              {partner?.partnerId && (
                <div 
                  style={{ 
                    fontSize: '14px', 
                    color: '#8c8c8c', 
                    fontWeight: 'normal',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    navigator.clipboard.writeText(partner.partnerId);
                    message.success('Partner ID copied to clipboard');
                  }}
                  title="Click to copy"
                >
                  ID: {partner.partnerId}
                </div>
              )}
            </StyledTitle>
            {partner && (
              <ActionButton
                danger={partner.status === Status.ACTIVE}
                onClick={() => handleActivateDeactivate(
                  partner.status === Status.ACTIVE ? 'deactivate' : 'activate'
                )}
              >
                {partner.status === Status.ACTIVE ? 'Deactivate' : 'Activate'}
              </ActionButton>
            )}
          </FormHeader>

          {error && (
            <Alert 
              message={error} 
              type="error" 
              showIcon 
              closable 
              onClose={() => setError(null)}
              style={{ marginBottom: 8 }}
            />
          )}

          <Form
            form={form}
            layout="vertical"
            onFinish={handleFormSubmit}
            initialValues={INITIAL_VALUES}
          >
            <StyledTabs defaultActiveKey="basic">
              <TabPane tab="Basic Information" key="basic">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="name"
                      label="Partner Name"
                      rules={[ValidationRules.REQUIRED]}
                    >
                      <Input disabled={!!partner} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="authDomain"
                      label="Auth Domain"
                      tooltip="Domain used for user registration (e.g., domain.com)"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="description"
                      label="Description"
                    >
                      <Input.TextArea rows={4} />
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tab="Authentication" key="auth">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="publicKeySharingMethod"
                      label="Public Key Sharing Method"
                      rules={[ValidationRules.REQUIRED]}
                    >
                      <Select>
                        {Object.entries(PublicKeySharingMethod).map(([key, value]) => (
                          <Option key={key} value={value}>{key}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="publicKeyLocation"
                      label="Public Key Location"
                      rules={[ValidationRules.REQUIRED]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="sukiAppId"
                      label="Suki App ID"
                      rules={[ValidationRules.REQUIRED]}
                      tooltip="The Suki App ID as registered in Suki's Okta"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <StyledCard title="Authentication Details" size="small">
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name={['authenticationDetails', 'aud']}
                        label="Audience"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={['authenticationDetails', 'leeway_in_mins']}
                        label="Leeway (minutes)"
                      >
                        <InputNumber min={0} style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name={['authenticationDetails', 'user_identifier']}
                        label="User Identifier"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={['authenticationDetails', 'user_domain']}
                        label="User Domain"
                        tooltip="Domain used for user authentication (e.g., domain.com)"
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </StyledCard>
              </TabPane>

              <TabPane tab="Configuration" key="config">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="partnerType"
                      label="Partner Type"
                      rules={[ValidationRules.REQUIRED]}
                    >
                      <Select>
                        {Object.entries(PartnerType).map(([key, value]) => (
                          <Option key={key} value={value}>{key}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="accessLevel"
                      label="Access Level"
                      rules={[ValidationRules.REQUIRED]}
                    >
                      <Select>
                        {Object.entries(PartnerAccessLevel).map(([key, value]) => (
                          <Option key={key} value={value}>{key}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </TabPane>

              <TabPane tab="Webhooks" key="webhooks">
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="webhookUrl"
                      label="Webhook URL"
                      tooltip="Endpoint where webhook events will be sent"
                    >
                      <Input placeholder="https://api.example.com/webhooks" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="webhookAuthType"
                      label="Authentication Type"
                      rules={[ValidationRules.REQUIRED]}
                    >
                      <Select>
                        {Object.entries(WebhookAuthenticationType).map(([key, value]) => (
                          <Option key={key} value={value}>{key}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="webhookFormat"
                      label="Webhook Format"
                      rules={[ValidationRules.REQUIRED]}
                    >
                      <Select>
                        {Object.entries(WebhookFormat).map(([key, value]) => (
                          <Option key={key} value={value}>{key}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="webhookSecret"
                      label="Webhook Secret"
                      dependencies={['webhookAuthType']}
                      rules={[
                        ({ getFieldValue }) => ({
                          required: getFieldValue('webhookAuthType') === WebhookAuthenticationType.HMAC,
                          message: 'Webhook Secret is required for HMAC authentication'
                        })
                      ]}
                    >
                      <Input.Password />
                    </Form.Item>
                  </Col>
                </Row>

                <StyledCard title="Authentication Details" size="small">
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) => 
                      prevValues.webhookAuthType !== currentValues.webhookAuthType
                    }
                  >
                    {({ getFieldValue }) => {
                      const authType = getFieldValue('webhookAuthType');
                      return (
                        <Row gutter={16}>
                          {authType === WebhookAuthenticationType.BASIC && (
                            <>
                              <Col span={12}>
                                <Form.Item
                                  name={['webhookAuthDetails', 'username']}
                                  label="Username"
                                  rules={[ValidationRules.REQUIRED]}
                                >
                                  <Input />
                                </Form.Item>
                              </Col>
                              <Col span={12}>
                                <Form.Item
                                  name={['webhookAuthDetails', 'password']}
                                  label="Password"
                                  rules={[ValidationRules.REQUIRED]}
                                >
                                  <Input.Password />
                                </Form.Item>
                              </Col>
                            </>
                          )}
                          {authType === WebhookAuthenticationType.BEARER && (
                            <Col span={24}>
                              <Form.Item
                                name={['webhookAuthDetails', 'token']}
                                label="Bearer Token"
                                rules={[ValidationRules.REQUIRED]}
                              >
                                <Input.Password />
                              </Form.Item>
                            </Col>
                          )}
                          {authType === WebhookAuthenticationType.XAPIKEY && (
                            <Col span={24}>
                              <Form.Item
                                name={['webhookAuthDetails', 'apiKey']}
                                label="API Key"
                                rules={[ValidationRules.REQUIRED]}
                              >
                                <Input.Password />
                              </Form.Item>
                            </Col>
                          )}
                          {authType === WebhookAuthenticationType.CUSTOM && (
                            <Col span={24}>
                              <Form.Item
                                name={['webhookAuthDetails', 'headers']}
                                label="Custom Headers"
                                rules={[ValidationRules.REQUIRED]}
                              >
                                <Input.TextArea 
                                  placeholder="Enter custom headers as JSON"
                                  rows={4}
                                />
                              </Form.Item>
                            </Col>
                          )}
                        </Row>
                      );
                    }}
                  </Form.Item>
                </StyledCard>

                <StyledCard title="Advanced Settings" size="small">
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name={['webhookSettings', 'retryCount']}
                        label="Retry Count"
                        tooltip="Number of retry attempts for failed webhook deliveries"
                      >
                        <InputNumber min={0} max={10} style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={['webhookSettings', 'timeoutSeconds']}
                        label="Timeout (seconds)"
                        tooltip="Maximum time to wait for webhook response"
                      >
                        <InputNumber min={1} max={30} style={{ width: '100%' }} />
                      </Form.Item>
                    </Col>
                  </Row>
                </StyledCard>
              </TabPane>
            </StyledTabs>

            <FormActions>
              <Space>
                <ActionButton 
                  type="primary" 
                  htmlType="submit" 
                  loading={loading}
                  primary
                >
                  {partner ? 'Update Partner' : 'Create Partner'}
                </ActionButton>
                <ActionButton 
                  htmlType="button" 
                  onClick={() => form.resetFields()}
                >
                  Clear
                </ActionButton>
              </Space>
            </FormActions>
          </Form>
        </Space>
      </FormContainer>
    </Spin>
  );
};

PartnerForm.propTypes = {
  partner: PropTypes.object,
  onSubmit: PropTypes.func.isRequired
};

export { PartnerForm };