import {
  Status,
  PartnerType,
  PartnerAccessLevel,
  NON_UPDATABLE_FIELDS
} from "./constants";

export const transformPartnerToForm = (partner) => {
  return {
    ...partner,
    authenticationDetails: {
      aud: partner.authenticationDetails?.aud || "",
      leeway_in_mins: partner.authenticationDetails?.leeway_in_mins || 0,
      user_domain: partner.authenticationDetails?.user_domain || "",
      user_identifier: partner.authenticationDetails?.user_identifier || "",
    },
    webhookAuthDetails: partner.webhookAuthDetails || {},
  };
};

export const transformFormToPartner = (formData) => {
  const partner = {
    ...formData,
    publicKeyLocation: formData.publicKeyLocation || undefined,
    webhookUrl: formData.webhookUrl || undefined,
    authenticationDetails: formData.authenticationDetails || undefined,
    webhookAuthDetails: formData.webhookAuthDetails || undefined,
  };

  // Remove empty/undefined values
  Object.keys(partner).forEach((key) => {
    if (partner[key] === undefined || partner[key] === "") {
      delete partner[key];
    }
  });

  return partner;
};

export const getUpdatePaths = (originalPartner, updatedPartner) => {
  const changedFields = [];

  Object.keys(updatedPartner).forEach((key) => {
    if (
      JSON.stringify(originalPartner[key]) !==
      JSON.stringify(updatedPartner[key])
    ) {
      // Check if field is allowed to be updated
      if (!NON_UPDATABLE_FIELDS.includes(key)) {
          changedFields.push(key);
      }
    }
  });

  return {
    paths: changedFields,
  };
};

export const validatePartner = (partner) => {
  const errors = [];

  // Required field validations
  if (!partner.name?.trim()) {
    errors.push("Name is required");
  }

  // Enum validations
  if (partner.partnerType === PartnerType.UNKNOWN) {
    errors.push("Partner type must be specified");
  }

  if (partner.accessLevel === PartnerAccessLevel.UNKNOWN) {
    errors.push("Access level must be specified");
  }

  // Conditional validations
  if (partner.status && partner.status !== Status.DEFAULT) {
    if (
      !partner.publicKeyLocation &&
      partner.publicKeySharingMethod !== "JWTASSERTION"
    ) {
      errors.push(
        "Public key location is required for the selected sharing method"
      );
    }
  }

  return errors;

};
