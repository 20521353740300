import React, { Component } from 'react'
import { DeleteCompositions, GetNotePDF } from '@sukiai/gql/admin'
import Divider from 'material-ui/Divider'
import FontIcon from 'material-ui/FontIcon'
import MenuItem from 'material-ui/MenuItem'
import { compose, withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { setActionRequested } from '../../actions'
import { titleBarItems, demoGrey } from '../../styles/colors'
import { menuFontSize } from '../../styles/typography'
import { navBarHeight } from '../../styles/dimensions'
import { ROUTES, FETCH_POLICY, GQL_STATUS } from '../../lib/constants'
import { logout, setError } from '../../lib/util'
import client from '../../apollo'
import get from 'lodash.get'

const DELETE_NOTE_CONFIRMATION = 'Are you sure you want to delete this note?'

const styles = {
  userMenu: {
    color: titleBarItems,
    fontSize: menuFontSize,

    height: '100%',
    minHeight: 0,
    lineHeight: navBarHeight,
    fontWeight: 500
  },
  inner: {
    paddingRight: 42
  },
  arrow: {
    fontSize: 48,
    margin: 0,
    height: navBarHeight,
    width: navBarHeight,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  menuItem: {
    color: titleBarItems
  },
  divider: {
    backgroundColor: demoGrey
  }
}

class UserMenu extends Component {
  state = {
    menu: []
  }

  componentDidMount () {
    const { match } = this.props

    let SIGNOUT_MENUITEM = []
    let ADMIN_MENU = []
    const NOTE_ACTIONS = []

    SIGNOUT_MENUITEM = [
      <MenuItem
        value='signout'
        primaryText='Sign Out'
        style={styles.menuItem}
        onClick={logout}
        data-cy='menu-item-log-out'
        key='signout'
      />
    ]

    ADMIN_MENU = [
      <MenuItem
        value='admin_orgs'
        primaryText='Organizations'
        style={styles.menuItem}
        rightIcon={<FontIcon className='material-icons' color={titleBarItems}>dashboard</FontIcon>}
        onClick={this.handleToAdminDashboard}
        data-cy='menu-item-orgs'
        key='admin-orgs'
      />,
      <MenuItem
        value='editor_sandbox'
        primaryText='Editor Sandbox'
        style={styles.menuItem}
        rightIcon={<FontIcon className='material-icons' color={titleBarItems}>gavel</FontIcon>}
        onClick={this.handleToEditorSandbox}
        data-cy='menu-item-editorsandbox'
        key='editor-sandbox'
      />,
      <MenuItem
        value='feature_flags'
        primaryText='Feature flags'
        style={styles.menuItem}
        rightIcon={<FontIcon className='material-icons' color={titleBarItems}>flag</FontIcon>}
        onClick={this.handleNavigateToFeatureFlags}
        data-cy='menu-item-feature-flags'
        key='feature-flags'
      />,
      <MenuItem
        value='partners'
        primaryText='Partners'
        style={styles.menuItem}
        rightIcon={<FontIcon className='material-icons' color={titleBarItems}>group</FontIcon>}
        onClick={this.handleNavigateToPartners}
        data-cy='menu-item-partners'
        key='partners'
      />,
      // <MenuItem
      //   value='commandcentral'
      //   primaryText='Commands'
      //   style={styles.menuItem}
      //   rightIcon={<FontIcon className='material-icons' color={titleBarItems}>hearing</FontIcon>}
      //   onClick={this.toCommandCentral}
      //   data-cy={'menu-item-cmds'}
      // />,
      <Divider style={styles.divider} key='divider-1' />
    ]

    const path = match?.path || ''
    if (path.includes('/note')) {
      NOTE_ACTIONS.push(
        <MenuItem
          value='deleteNote'
          primaryText='Delete Note in Suki'
          style={styles.menuItem}
          onClick={this.handleShowDeleteNoteModal}
          data-cy='delete-note-modal'
          key='delete-note'
        />
      )
    }

    if (path.includes('/note') || path.includes('/patientnote')) {
      NOTE_ACTIONS.push(
        <MenuItem
          value='printNote'
          primaryText='Print Note'
          style={styles.menuItem}
          onClick={this.handlePrintNote}
          data-cy='menu-item-print-note'
          key='print-note'
        />
      )
    }

    NOTE_ACTIONS.push(<Divider style={styles.divider} key='divider-2' />)

    this.setState({
      menu: [...ADMIN_MENU, ...NOTE_ACTIONS, ...SIGNOUT_MENUITEM]
    })
  }

  handleToAdminDashboard = () => {
    this.props.history.push(ROUTES.ORGS)
  }

  handleToEditorSandbox = () => {
    this.props.history.push(ROUTES.EDITOR_SANDBOX)
  }

  handleNavigateToFeatureFlags = () => {
    this.props.history.push(ROUTES.FEATURE_FLAGS)
  }

  handleNavigateToPartners = () => {
    this.props.history.push(ROUTES.PARTNERS)
  }

  // TODO: Create a react modal dialog and import it here.
  handleShowDeleteNoteModal = () => {
    const res = window.confirm(DELETE_NOTE_CONFIRMATION)
    if (res) {
      const { match, client, history } = this.props
      const orgId = get(match, 'params.orgId')
      const noteId = get(match, 'params.noteId')
      client.mutate({
        mutation: DeleteCompositions,
        variables: {
          organizationId: orgId,
          ids: [noteId]
        }
      }).then(({ data }) => {
        window.Logger.info(`Deleted note in admin view [noteId: ${data.ids}]`)
        history.goBack()
      }).catch(err => {
        console.error(err)
      })
    }
  }

  handlePrintNote = () => {
    const { match } = this.props

    const orgId = get(match, 'params.orgId')
    const noteId = get(match, 'params.noteId')
    const isPatientNote = match.path.includes('patientnote')

    const queryVars = {}
    queryVars.organizationId = orgId
    if (isPatientNote) {
      queryVars.noteId = noteId
    } else {
      queryVars.compositionId = noteId
    }

    client.query({
      query: GetNotePDF,
      variables: queryVars,
      fetchPolicy: FETCH_POLICY.NETWORK_ONLY
    }).then(({ data }) => {
      const uri = get(data, 'notePDF.pdf.uri')
      if (uri) {
        window.open(uri, '_blank')
      } else {
        setError('Could not print note. Please try again later.')
      }
    }).catch(err => {
      const gqlError = get(err, 'graphQLErrors[0].status')
      if (gqlError === GQL_STATUS.FAILED_PRECONDITION) {
        setError('Failed precondition.')
        return
      }
      console.error('Could not print note:', err)
      setError('Could not print note. Please try again later.')
    })
  }

  render () {
    return (
      <div id='topNavUserMenu' style={this.props.style}>
        <MenuItem
          style={styles.userMenu}
          innerDivStyle={styles.inner}
          primaryText={this.props.user}
          rightIcon={<FontIcon className='material-icons' color={titleBarItems} style={styles.arrow}>arrow_drop_down</FontIcon>}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          targetOrigin={{ horizontal: 'right', vertical: 'top' }}
          menuItems={this.state.menu}
          data-cy='top-nav-menu'
        />
      </div>
    )
  }
}

const mapStateToProps = ({ note }) => ({
  currentNoteId: note.id
})

const mapDispatchToProps = dispatch => ({
  setActionRequested: type => dispatch(setActionRequested(type))
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withApollo,
  withRouter
)(UserMenu)
