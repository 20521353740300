import React, { Component } from 'react'
import RaisedButton from 'material-ui/RaisedButton'
import OktaAuth from '@okta/okta-auth-js'
import TextField from 'material-ui/TextField'
import { whiteSmoke } from '../../styles/colors'
import { LO_STO } from '../../lib/constants'
import loSto from '../../config/loSto'
import { OktaConfig } from '../../config'
import './Login.css'
import { setError } from '../../lib/util'

const styles = {
  container: {
    backgroundColor: 'transparent',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  textfield: {
    height: '72px'
  },
  text: {
    color: 'white',
    fontSize: 32,
    fontWeight: 200
  },
  hintText: {
    color: 'white',
    fontSize: 32,
    fontWeight: 200,
    marginBottom: '12px'
  },
  menuItem: {
    fontSize: 26,
    fontWeight: 'normal'
  },
  selected: {
    color: 'black'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 50,
    width: 170
  },
  button: {
    height: 55,
    lineHeight: '55px'
  },
  label: {
    fontSize: 32,
    fontWeight: 'normal',
    textTransform: 'none',
    flex: 1
  }
}

const ENTER_KEY = 13

class LoginModal extends Component {
  constructor (props) {
    super(props)

    this.state = {
      username: '',
      password: '',
      loading: false,
      error: false
    }

    this.oktaAuth = new OktaAuth({
      url: OktaConfig.baseURL,
      issuer: OktaConfig.auth.issuer,
      clientId: OktaConfig.auth.client_id,
      redirectUri: OktaConfig.auth.redirect_uri,
      pkce: false,
      onAuthRequired: OktaConfig.auth.onAuthRequired
    })
  }

  handleUsernameChange = (event) => {
    this.setState({
      username: event.target.value
    })
  }

  handlePasswordChange = (event) => {
    this.setState({
      password: event.target.value
    })
  }

  handleLogin = () => {
    this.setState({ loading: true })
    this.oktaAuth.signIn({
      username: this.state.username,
      password: this.state.password
    }).then(res => {
      if (res.status === 'SUCCESS') {
        loSto.set(LO_STO.SESSION_TOKEN, res.sessionToken)
        this.oktaAuth.token.getWithRedirect({
          responseType: ['token', 'id_token'],
          responseMode: 'fragment',
          sessionToken: res.sessionToken,
          scopes: ['openid', 'email', 'profile']
        })
      } else {
        this.setState({
          loading: false,
          error: true
        })
        console.error('Authentication error:', res)
        setError('Authentication error. Check email and password.')
      }
    }).catch(err => {
      this.setState({
        loading: false,
        error: true
      })
      console.error('Login error:', err)
      setError('Error logging in. Try different credentials or contact an administrator.')
    })
  }

  handleTouchTap = () => {
    this.handleLogin()
  }

  handleKeyPress = e => {
    if (e.key === 'Enter' || e.keyCode === ENTER_KEY) {
      this.handleLogin()
    }
  }

  render () {
    return (
      <div style={Object.assign({}, styles.container, this.props.style)}>
        <TextField
          id='usernameTextField'
          fullWidth
          style={styles.textfield}
          hintStyle={styles.hintText}
          inputStyle={styles.text}
          hintText='Username'
          onKeyPress={this.handleKeyPress}
          onChange={this.handleUsernameChange}
          value={this.state.username}
          data-cy='textfield-username'
        />
        <TextField
          id='passwordTextField'
          fullWidth
          style={styles.textfield}
          inputStyle={styles.text}
          type='password'
          onKeyPress={this.handleKeyPress}
          onChange={this.handlePasswordChange}
          value={this.state.password}
          data-cy='textfield-password'
        />
        <div style={styles.buttonContainer}>
          <RaisedButton
            id='loginBtn'
            buttonStyle={styles.button}
            overlayStyle={styles.button}
            backgroundColor={whiteSmoke}
            fullWidth
            label='Log In'
            labelStyle={styles.label}
            onClick={this.handleTouchTap}
            onKeyPress={this.handleKeyPress}
            data-cy='btn-login'
          />
        </div>
      </div>
    )
  }
}

export default LoginModal
