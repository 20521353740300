import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Menu, Typography, Pagination, Spin } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { fetchPartners } from '../../api';
import {
  ErrorContainer,
  StyledSider,
  HeaderContainer,
  NewPartnerButton,
  PartnerInfo,
  PartnerName,
  PartnerDomain,
  PaginationContainer,
  TotalCount,
  StyledMenu,
} from './styled';


const PAGE_SIZE = 20;

const PartnersList = ({ onPartnerSelect, selectedPartnerId, onNewPartner }) => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);

  const fetchPartnersList = async (isInitial = false) => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await fetchPartners({ 
        limit: PAGE_SIZE, 
        offset 
      });
      
      const newPartners = response.partners || [];
      
      setPartners(prev => isInitial ? newPartners : [...prev, ...newPartners]);
      setHasMore(newPartners.length === PAGE_SIZE);

      // Select first partner if none selected
      if (newPartners.length > 0 && !selectedPartnerId && isInitial) {
        onPartnerSelect(newPartners[0]);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Failed to fetch partners";
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPartnersList(true);
  }, []);

  const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && !loading && hasMore) {
      setOffset(prev => prev + PAGE_SIZE);
      fetchPartnersList();
    }
  };

  const handleNewPartner = () => {
    onNewPartner();
  };

  const handlePartnerSelect = (partner) => {
    onPartnerSelect(partner);
  };

  return (
    <StyledSider width={280}>
      <HeaderContainer>
        <NewPartnerButton
          color="primary"
          variant="outlined"
          icon={<PlusOutlined />}
          onClick={handleNewPartner}
          block
        >
          New Partner
        </NewPartnerButton>
      </HeaderContainer>
      
      <StyledMenu
        mode="inline"
        selectedKeys={selectedPartnerId ? [selectedPartnerId] : []}
        onScroll={handleScroll}
      >
        {partners.map((partner) => (
          <Menu.Item
            key={partner.partnerId}
            onClick={() => handlePartnerSelect(partner)}
          >
            <PartnerInfo>
              <PartnerName inactive={partner.status === "INACTIVE"}>
                {partner.name}
              </PartnerName>
              <PartnerDomain>
                {partner.authDomain}
              </PartnerDomain>
            </PartnerInfo>
          </Menu.Item>
        ))}
        {loading && (
          <Menu.Item disabled style={{ textAlign: 'center' }}>
            <Spin size="small" />
          </Menu.Item>
        )}
        {error && (
          <Menu.Item disabled>
            <Typography.Text type="danger">
              Failed to load partners. Please try again later.
            </Typography.Text>
          </Menu.Item>
        )}
      </StyledMenu>
    </StyledSider>
  );
};

PartnersList.propTypes = {
  onPartnerSelect: PropTypes.func.isRequired,
  selectedPartnerId: PropTypes.string,
  onNewPartner: PropTypes.func.isRequired,
};

export { PartnersList };
