import React, { useState } from "react";
import { Layout, Spin } from "antd";
import { PartnerForm, PartnersList } from "./components";
import { PageLayout, ContentLayout } from "./partners.styled";
import TopNavBar from "../../containers/TopNavBar";

const Partners = () => {
  const [selectedPartner, setSelectedPartner] = useState(null);

  const handlePartnerSelect = (partner) => {
    setSelectedPartner(partner);
  };

  const handleNewPartner = () => {
    setSelectedPartner(null);
  };

  return (
    <Layout>
      <TopNavBar />
      <PageLayout>
        <PartnersList
          selectedPartnerId={selectedPartner?.partnerId}
          onPartnerSelect={handlePartnerSelect}
          onNewPartner={handleNewPartner}
        />

        <ContentLayout>
          <PartnerForm
            partner={selectedPartner}
            onSubmit={(partner) => {
              // This will trigger a refetch in PartnersList through its useEffect
              setSelectedPartner(partner);
            }}
          />
        </ContentLayout>
      </PageLayout>
    </Layout>
  );
};

export default Partners;