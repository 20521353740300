import styled from 'styled-components';
import { Layout } from 'antd';


export const PageLayout = styled(Layout)`
  margin-top: 40px;
  height: calc(100vh - 40px);
`;

export const ContentLayout = styled(Layout)`
  padding: 24px;
  height: fit-content;
`;
