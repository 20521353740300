import axios from './axios';
import { message } from 'antd';

const handleApiError = (error) => {
  const errorMessage = error.response?.data?.message || 'Operation failed';
  message.error(errorMessage);
  throw error;
};

export const fetchPartners = async ({ limit = 100, offset = 0 } = {}) => {
  try {
    const response = await axios.get('/ext-partners', {
      params: {
        'pageRequest.type': 'CURSOR',
        'pageRequest.cursors.limit': limit,
        'pageRequest.cursors.offset': offset
      }
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const fetchPartner = async (partnerId) => {
  try {
    const response = await axios.get(`/ext-partners/${partnerId}`);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const createPartner = async (partner) => {
  try {
    const response = await axios.post('/ext-partners/register', {
      partner
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const updatePartner = async (partner, updatedFields) => {
  try {
    const response = await axios.patch('/ext-partners/update', {
      partner,
      updatePaths: updatedFields
    });
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
};

export const activatePartner = async (partnerId) => {
  try {
    await axios.patch(`/ext-partners/${partnerId}/activate`);
    return true;
  } catch (error) {
    handleApiError(error);
  }
};

export const deactivatePartner = async (partnerId) => {
  try {
    await axios.patch(`/ext-partners/${partnerId}/deactivate`);
    return true;
  } catch (error) {
    handleApiError(error);
  }
};