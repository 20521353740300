import React, { Component } from 'react'
import { Route, Router } from 'react-router-dom'
import client from './apollo'
import { ROUTES, LO_STO } from './lib/constants'
import loSto from './config/loSto'
import { isValidPathname, initializeUser } from './lib/util'
import { ApolloProvider } from 'react-apollo'
import Auth from './okta'
import history from './history'
import { Security, LoginCallback } from '@okta/okta-react'
import SecureRoute from './components/SecureRoute'
import NotificationsOverlay from './components/NotificationsOverlay'
import { ErrorModal } from './components/Error'
import NotFound from './components/NotFound'
import { fontFamily } from './styles/typography'
import Nvoq from './containers/Nvoq'
import CommandCentral from './containers/CommandCentral'
import AppointmentManager from './containers/AppointmentManager'
import EMR from './containers/EMR'
import EditorSandbox from './containers/EditorSandbox'
import Note from './containers/Note'
import NoteTypes from './containers/NoteTypes'
import Orgs from './containers/Orgs'
import PendingUsers from './containers/PendingUsers'
import Preferences from './containers/Preferences'
import SpecialityMap from './containers/SpecialityMap'
import Root from './containers/Root'
import Scripts from './containers/Scripts'
import SectionManager from './containers/SectionMapper'
import Users from './containers/Users'
import { FeatureFlags, Partners } from './pages'

const style = {
  fontFamily
}

// To make sure we don't expose routes outside other than the Login & Logout
// please add SecureRoutes instead
class Routes extends Component {
  state = {
    userLoaded: loSto.get(LO_STO.USER_ID)
  }

  /*
   *  All Components that are always rendered go here
   */
  alwaysOnComponents = () => ([
    <ErrorModal key='error-modal' history={history} />,
    <NotificationsOverlay key='notif-overlay' history={history} />
  ])

   checkFragment = () => {
     const hash = history?.location?.hash
     if (hash.includes('error=access_denied')) {
       loSto.set(LO_STO.ALTAIS_ERROR, true)
       history.push(ROUTES.ORGS)
     }

     return null
   }

  loadUser = async () => {
    await initializeUser(Auth)
    this.setState({ userLoaded: true })
  }

  validateRoute = () => {
    if (!this.state.userLoaded) {
      this.loadUser()
      return null
    }

    return isValidPathname(window.location.pathname)
      ? this.alwaysOnComponents()
      : <NotFound />
  }

  render () {
    return (
      <Router history={history}>
        <Security authService={Auth}>
          <ApolloProvider client={client}>
            <div style={style}>
              {/* Authentication */}
              <Route exact path={ROUTES.ROOT} component={Root} />

              {/* Always on */}
              <SecureRoute path={ROUTES.ADMIN_VIEW} render={this.validateRoute} />

              {/* Contextual */}
              {this.state.userLoaded &&
                [
                  <SecureRoute exact path={ROUTES.ORGS} component={Orgs} key='orgs' />,
                  <SecureRoute exact path={`/:orgId/${ROUTES.USERS}`} component={Users} key='docs' />,
                  <SecureRoute exact path={`/:orgId/${ROUTES.PENDING_USERS}`} component={PendingUsers} key='pending-users' />,
                  <SecureRoute exact path={`/:orgId/${ROUTES.APPTS}`} component={AppointmentManager} key='appts' />,
                  <SecureRoute exact path={`/${ROUTES.COMMAND_CENTRAL}`} component={CommandCentral} key='cmmnd-central' />,
                  <SecureRoute exact path={`/${ROUTES.NOTE_TYPES}/:orgId/:userId?`} component={NoteTypes} key='note-types' />,
                  <SecureRoute exact path={`${ROUTES.PREFERENCES}/:orgId/:userId?`} component={Preferences} key='preferences' />,
                  <SecureRoute exact path={`${ROUTES.SPECIALITIES}/:orgId/:userId?`} component={SpecialityMap} key='specialities' />,
                  <SecureRoute exact path={`/:organizationId/:userId/${ROUTES.SCRIPTS}`} component={Scripts} key='scripts' />,
                  <SecureRoute exact path={ROUTES.EDITOR_SANDBOX} component={EditorSandbox} key='editor-sandbox' />,
                  <SecureRoute exact path={`/:orgId/:userId/${ROUTES.NOTE}/:noteId`} component={Note} key='note' />,
                  <SecureRoute exact path={`/:orgId/:userId/${ROUTES.PATIENT_NOTE}/:noteId`} component={Note} key='patient-note' />,
                  <SecureRoute exact path={`/:orgId/${ROUTES.EMR}`} component={EMR} key='emr' />,
                  <SecureRoute exact path={`/:orgId/${ROUTES.SECTIONS}`} component={SectionManager} key='sections' />,
                  <SecureRoute exact path={`/:organizationId/:userId/${ROUTES.NVOQ}`} component={Nvoq} key='nvoq' />,
                  <SecureRoute exact path={ROUTES.FEATURE_FLAGS} component={FeatureFlags} key='feature-flags' />,
                  <SecureRoute exact path={ROUTES.PARTNERS} component={Partners} key='partners-home' />
                ]}

              {/* Developer */}
              <Route path={ROUTES.IMPLICIT_CALLBACK} component={LoginCallback} />
              <Route path={ROUTES.IMPLICIT_CALLBACK} render={this.checkFragment} />
            </div>
          </ApolloProvider>
        </Security>
      </Router>
    )
  }
}

export default Routes
