import styled from 'styled-components';
import { Card, Tabs, Button, Typography, Form } from 'antd';

const { Title } = Typography;

export const FormContainer = styled.div`
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.1);
  padding: 24px;
  height: fit-content;
`;

export const FormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const StyledTitle = styled(Title)`
  margin: 0 !important;
`;

export const StyledCard = styled(Card)`
  margin-top: 24px;
  border-radius: 8px;
  border-color: #e2e8f0;
  
  .ant-card-head {
    min-height: 48px;
    padding: 0 16px;
    border-bottom-color: #e2e8f0;
  }
  
  .ant-card-head-title {
    padding: 12px 0;
    font-size: 14px;
    font-weight: 500;
  }
  
  .ant-card-body {
    padding: 16px;
  }
`;

export const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 24px;
  }
  
  .ant-tabs-tab {
    padding: 8px 16px;
    margin: 0 8px 0 0;
    
    &.ant-tabs-tab-active {
      font-weight: 500;
    }
  }

  .ant-tabs-content {
    background: #fff;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid #e2e8f0;
  }
`;

export const FormActions = styled.div`
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #e2e8f0;
`;

export const ActionButton = styled(Button)`
  height: 36px;
  border-radius: 6px;
  font-weight: 500;
  ${props => props.primary && `
    padding: 0 24px;
  `}
`;

export const StyledFormItem = styled(Form.Item)`
  .ant-input,
  .ant-select-selector,
  .ant-input-number {
    border-radius: 6px;
    border-color: #e2e8f0;
    
    &:hover {
      border-color: #cbd5e1;
    }
    
    &:focus {
      border-color: #3b82f6;
      box-shadow: 0 0 0 2px rgba(59, 130, 246, 0.1);
    }
  }

  .ant-input-number {
    width: 100%;
  }

  .ant-select-selector {
    height: 36px !important;
    
    .ant-select-selection-item {
      line-height: 34px !important;
    }
  }

  textarea.ant-input {
    min-height: 120px;
  }
`; 